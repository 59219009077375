import {
  Show,
  createSignal,
  Suspense,
  Switch,
  Match,
  ErrorBoundary,
} from "solid-js";
import {
  createAsync,
  type RouteDefinition,
  type RouteSectionProps,
  useSearchParams,
  useAction,
} from "@solidjs/router";
import { Meta } from "@solidjs/meta";
import { useProduct } from "~/services/products/useProduct";
import { useSessionContext, useSiteContext } from "~/utils/contexts";
import { galleryFrameSizes, gfSizesByCode } from "~/utils/builtFrames";
import {
  ProductHeader,
  SwatchDisplay,
  PFGFPricingBox,
  FrameCounter,
  ProductDescription,
  ProductDetails,
  FrameChart,
  BuiltFrameCareInstr,
} from "~/components/product/product-page";
import { Modal, BaseSkeleton } from "~/components/utility";
import { Icon } from "solid-heroicons";
import { check } from "solid-heroicons/outline";
import { PT } from "~/utils/products";
import { EventType } from "@solid-primitives/analytics";
import { framePayloadGenerator } from "~/utils/builtFrames";
import { addToCartAction } from "~/services/cart";
import { ProductPageButtons } from "../[sku]";

export const route = {
  preload: ({ params }) =>
    useProduct(params.sku, {
      type: PT.GALLERYFRAME,
      available_list: true,
      suggestions: true,
    }),
} satisfies RouteDefinition;

export default function GalleryFrame(props: RouteSectionProps) {
  const { isPartner, permission, aCartActionIsPending } = useSessionContext();
  const { global, setPanel, track, trackFBQ } = useSiteContext();
  const [params, _] = useSearchParams<{ size: string }>();
  const [qty, setQty] = createSignal(6);
  const [pricing, setPricing] = createSignal();
  const [showChart, setShowChart] = createSignal(false);
  const status = {
    READY: 0,
    LOADING: 1,
    ADDED: 2,
    ERROR: 3,
  };
  const [addCartStatus, setAddCartStatus] = createSignal(status.READY);

  const product = createAsync(async () => {
    return await useProduct(props.params.sku, {
      type: PT.GALLERYFRAME,
      available_list: true,
      suggestions: true,
    });
  });

  const size = () => {
    if (isPartner() && !params.size) {
      // setParams({ size: 1620 }, { replace: false });
      return galleryFrameSizes[0].code;
    } else if (!params.size) {
      return "1620";
    }
    return params.size;
  };

  // const frameSizeOptions = Object.entries(gfSizesByCode).map(([code, data]) => {
  //   return {
  //     value: code,
  //     label: (
  //       <span>
  //         {data.size[0]}
  //         <span class="text-xs mx-1">&#10005;</span>
  //         {data.size[1]}
  //       </span>
  //     ),
  //   };
  // });

  const frameSizeOptions = Object.entries(gfSizesByCode).map(([code, data]) => {
    return {
      value: code,
      label: `${data.size[0]} x ${data.size[1]}`,
      width: data.size[0],
      height: data.size[1],
    };
  });

  const addToCart = useAction(addToCartAction);

  const addFrameToCart = async (payload: any) => {
    const [item] = payload;
    setAddCartStatus(status.LOADING);
    try {
      await addToCart(item);
      setAddCartStatus(status.ADDED);
      setPanel({ mode: "cart", open: true });
    } catch (error) {
      console.log("ERROR ADDING GALLERYFRAME TO CART : ", error);
      setAddCartStatus(status.ERROR);
    }
  };

  return (
    <>
      <Show when={product() && global()}>
        <div class="!border-t-0">
          <Meta name="Algolia crawler" content="noindex" />
          <ProductHeader
            product={product}
            type={PT.GALLERYFRAME}
            size={params.size}
          />
          <ErrorBoundary
            fallback={(error, reset) => {
              return (
                <div class="bg-red-100 rounded-md p-3 text-sm">
                  <p>
                    Error retrieving pricing information. Please try again
                    later, we apologize for the inconvenience.
                  </p>
                  {/* TODO - sentry  */}
                </div>
              );
            }}
          >
            <Suspense fallback={<BaseSkeleton height={465} />}>
              <Show
                when={isPartner()}
                fallback={
                  <SwatchDisplay product={product} type={PT.GALLERYFRAME} />
                }
              >
                <PFGFPricingBox
                  product={product}
                  sizes={galleryFrameSizes}
                  sizeOptions={frameSizeOptions}
                  activeSize={size}
                  sku={props.params.sku}
                  productType={PT.GALLERYFRAME}
                  setPricing={setPricing}
                  sizeGuide={() => {
                    setShowChart(true);
                  }}
                />
                <Show when={permission.PLACEORDER}>
                  <div class="grid grid-cols-2 sm:grid-cols-3 gap-x-2">
                    <FrameCounter
                      minimum={6}
                      getCount={qty}
                      updateCount={setQty}
                      label="Quantity"
                    />
                  </div>
                </Show>
              </Show>
              <ProductPageButtons
                product={product()!}
                buttonText={
                  <>
                    <Switch>
                      <Match when={addCartStatus() === status.READY}>
                        Add to Cart
                      </Match>
                      <Match when={addCartStatus() === status.ADDED}>
                        <span class="flex items-center">
                          Added
                          <Icon
                            path={check}
                            class="w-4 inline-block ml-2"
                            stroke-width={3}
                          />
                        </span>
                      </Match>
                      <Match when={addCartStatus() === status.LOADING}>
                        Adding...
                      </Match>
                      <Match when={addCartStatus() === status.ERROR}>
                        Error
                      </Match>
                    </Switch>
                  </>
                }
                disabled={aCartActionIsPending()}
                onOrderClick={() => {
                  track(EventType.Event, {
                    category: "add_to_cart",
                    action: "PF Added",
                    value: (pricing() as any)?.[size()]?.Amount ?? "",
                    // @ts-expect-error
                    currency: (pricing() as any)?.[size()]?.Currency ?? "",
                    items: [
                      {
                        item_id: `${props.params.sku}-${size()}`,
                        item_name: product()?.Name,
                        item_category: PT.GALLERYFRAME,
                        item_category2: product()?.Category,
                        item_category3: product()?.Collection,
                        quantity: qty(),
                        price: (pricing() as any)?.[size()]?.Amount ?? "",
                      },
                    ],
                  });
                  trackFBQ("AddToCart", {
                    content_category: PT.GALLERYFRAME,
                    content_name: product()?.Name,
                    content_ids: [`${product()?.SKU}-${size()}`],
                    contents: [
                      { id: `${product()?.SKU}-${size()}`, quantity: qty() },
                    ],
                    currency: (pricing() as any)?.[size()]?.Currency ?? "",
                    value: (pricing() as any)?.[size()]?.Amount ?? "",
                  });
                  addFrameToCart(
                    framePayloadGenerator(
                      props.params.sku,
                      [size()],
                      PT.GALLERYFRAME,
                      qty()
                    )
                  );
                }}
              />
            </Suspense>
          </ErrorBoundary>
        </div>
        <ProductDescription description={product()?.Description as string} />
        <ProductDetails product={product} hideAvailableAs>
          <ul class="list-inside list-disc pb-3 text-sm text-roma-dark-grey pl-2">
            <li>Crystal clear plexiglass</li>
            <li>Bright white mat</li>
            <li>Cardboard backing</li>
            <li>Hardware and wire included</li>
            <li>Custom made with love and care</li>
            <li>Solid wood frame made in Italy</li>
            <li>Assembled in the USA and Canada</li>
            <li>Durable and scratch resistant finish</li>
            <li>100% Happiness Guarantee</li>
          </ul>
        </ProductDetails>
        <BuiltFrameCareInstr />
        <Modal
          open={showChart}
          onClose={() => {
            setShowChart(false);
          }}
          width="5xl"
        >
          <FrameChart mouldingWidth={product()?.Width!} />
        </Modal>
      </Show>
    </>
  );
}
